import config from "./config.json"
// const { name, version } = config.tagManager

import GA4 from './tag-manager/gtm/ga4'
export default new GA4(config.tagManager)

// const build = async ()=> {
//     const tm = await import(`./tag-manager/${name}/${version}.js`);
//
//     return tm.default;
// }

// export default new tm(config.tagManager);