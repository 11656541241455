import TagManager from "../tag-manager";

export default class GA4 extends TagManager
{
    addToCart = () => {
        const { product } = this.state;

        const response = {
            event: "add_to_cart",
            ecommerce: {
                currency: "EUR",
                value: product.price,
                items: [product]
            }
        }

        console.debug('item added to cart: ', response);
        dataLayer.push(response);

        return this
    }

    removeFromCart = () => {
        const { product } = this.state;

        const response = {
            event: "remove_from_cart",
            ecommerce: {
                currency: "EUR",
                value: product.price,
                items: [product]
            }
        }

        console.debug('item removed from cart: ', response);
        dataLayer.push(response);

        return this
    }

    viewItem = () => {
        const { product } = this.state;

        const response = {
            event: "view_item",
            ecommerce: {
                currency: "EUR",
                value: product.price,
                items: [product]
            }
        }

        console.debug('item viewed: ', response);
        dataLayer.push(response);

        return this
    }

    viewCart = ()=> {
        const {cart} = this.state;

        const response = {
            event: "view_cart",
            ecommerce: {
                currency: "EUR",
                value: cart.total,
                items: cart.items
            },
        }

        console.debug('cart viewed: ', response);
        dataLayer.push(response);

        return this
    }

    addShippingInfo = ()=> {
        const {cart,options} = this.state;

        const response = {
            event: "add_shipping_info",
            ecommerce: {
                currency: "EUR",
                value: cart.total,
                shipping_tier: options?.shipping_tier ?? 'Ground',
                items: cart.items
            },
        }

        console.debug('shipping info added: ', response);
        dataLayer.push(response);

        return this
    }

    addPaymentInfo = ()=> {
        const {cart,options} = this.state;

        const response = {
            event: "add_payment_info",
            ecommerce: {
                currency: "EUR",
                value: cart.total,
                payment_type: options?.payment_type ?? 'iDeal',
                items: cart.items
            },
        }

        console.debug('payment info added: ', response);
        dataLayer.push(response);

        return this
    }

    beginCheckout = ()=> {
        const {cart} = this.state;

        const response = {
            event: "begin_checkout",
            ecommerce: {
                currency: "EUR",
                value: cart.total,
                items: cart.items
            },
        }

        console.debug('checkout begun: ', response);
        dataLayer.push(response);

        return this
    }
    purchase = ()=> {
        const {cart, options} = this.state;

        const response = {
            event: "purchase",
            ecommerce: {
                currency: "EUR",
                transaction_id: options.transaction_id,
                value: cart.total,
                tax: cart.tax,
                shipping: cart.shipping,
                items: cart.items
            },
        }

        console.debug('purchase completed: ', response);
        dataLayer.push(response);

        return this
    }
}