export default class TagManager
{
    name
    version
    state = { cart: {}, options: {}, product: {} }

    constructor({ name, version }) {
        this.name = name
        this.version = version
    }

    updateState = ({ cart, product, options })=> {
        const { cart: oldCart, product: oldProduct, options: oldOptions } = this.state;
        this.state = {
            cart: { ...oldCart, ...cart },
            product: { ...oldProduct, ...product },
            options: { ...oldOptions, ...options }
        }

        return this
    }

    updateFromField = (type, field)=> {
        const input = document.querySelector(`input[data-${this.name}-field=${field}]`);
        this.state[type][field] = input.value;

        return this
    }

    updateFromFields = (type, fields) => {
        fields.forEach(field => {
            this.updateFromField(type, field)
        })

        return this
    }
    listen = (action, callback, options = { type: 'click' }) => {
        const elements = document.querySelectorAll(`[data-${this.name}-action=${action}]`)
        elements.forEach(element => {
            element.addEventListener(options.type, event=> {
                callback({ event, element })
            })
        })

        return this
    }
}